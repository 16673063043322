<template>
    <div id="careers">
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <section class="section is-large mt-5 py-6">
                    <div class="container">
                        <h1 class="title xl p-0">Work With Us</h1>
                        <h2 class="title is-4">
                            Our culture stresses talent, autonomy, integrity, collaboration,
                            and a drive to exceed customer expectations.
                        </h2>
                        <p>
                            We offer a comprehensive benefits package,
                            a great deal of flexibility, and the opportunity
                            to work on interesting problems with highly capable
                            and professional colleagues. Many of our staff
                            work remotely.
                        </p>
                    </div>
                </section>
            </div>
        </div>
        <section class="section hero is-light">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-8-desktop">
                        <h2 class="title is-2 mt-0 has-text-centered">Current Openings</h2>
                        <div v-if="jobs.length > 0">
                            <div class="columns is-multiline is-centered">
                                <div v-for="job in jobs"
                                     :key="job.id"
                                     class="column is-half">
                                    <router-link :to="{ path: 'careers/' + job.url }"
                                                 class="job-tile box"
                                                 :class="{ 'is-draft': job.published_at === null }">
                                        <span class="has-text-weight-bold has-text-centered">
                                            <i class="is-ghost fa-fw fas fa-user-circle"></i>
                                            {{ job.title }}
                                        </span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <p v-else
                           class="is-size-5 has-text-centered pb-5">
                            There are no openings at this time.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <section class="section hero">
                    <div class="container">
                        <h2 class="title is-3 py-1 mt-4 mb-4 has-underline">Diversity, Equity, &amp; Inclusion</h2>
                        <p>
                            Eduworks is an equal opportunity employer and encourages
                            qualified applications from groups underrepresented in
                            STEM disciplines. Eduworks Corporation provides equal
                            employment opportunities to all employees and applicants
                            in all company facilities without regard to race (including
                            hair texture and hairstyles), color, religious creed,
                            sex, national origin, ancestry, pregnancy,
                            childbirth, physical disability, mental and/or intellectual
                            disability, age, military status, veteran status (including
                            protected veterans), marital status, registered domestic
                            partner or civil union status, familial status, gender
                            (including sex stereotyping and gender identity or expression),
                            medical condition (including, but not limited to, cancer
                            related or HIV/AIDS related), genetic information, or sexual
                            orientation in accordance with applicable federal, state and local laws.
                            This policy applies to all terms and conditions of employment,
                            including, but not limited to, hiring, placement, promotion,
                            termination, layoff, recall, transfer, leaves of absence,
                            compensation and training.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'careers',
    components: {},
    data: () => {
        return {
            teamImages: {
                image: 'url(../src/assets/Careers/work-with-us-1.jpg)'
            }
        }
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'Current Openings',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'Eduworks | %s'
    },
    mounted() {
        if (this.$store.state.jobs.length < 1) this.$store.dispatch('getJobs')
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl
        },
        jobs() {
            return this.$store.state.jobs
        }
    },
    methods: {
        notEmpty(data) {
            // check if optional strapi data item has a value
            // ex: v-if="notEmpty(job.howToApply)"
            if (!data) return false
            return true
        },
        getImgUrl(pic) {
            if (!pic) return
            return require('../assets/' + pic)
        },
        formatDate(date) {
            let d = new Date(date)
            let finalDate = d.toLocaleDateString()
            return finalDate
        }
    }
}
</script>
